










































































































































































































































































































































































































































































































































































































































































































































































.addClassCtr {
  .el-form {
    .form-item {
      width: 60%;
    }
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
